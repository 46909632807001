<template>
  <div>
    <fw-panel v-if="applications.length > 0" title="As minhas candidaturas" :counter="applications.length" class="my-5">
      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
        <CardApplication
          v-for="callApplication in applications"
          :key="callApplication.key"
          :call="callApplication"
          @click.native="
            goToApplication(callApplication.key, callApplication.application.key, callApplication.application.state)
          "
        />
      </div>
    </fw-panel>

    <fw-panel :title="$t('calls')" :counter="calls ? calls.length : 0" :counter-total="totalcalls" class="mt-10 mb-5">
      <fw-panel-info
        v-if="!loadingcalls && (!calls || !calls.length)"
        type="basic"
        simple
        clean
        class="text-center text-gray-500"
      >
        Não existem procedimentos com candidaturas abertas neste momento.
      </fw-panel-info>

      <LoadingPlaceholder v-if="loadingcalls" />
      <div v-else class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
        <template v-for="call in calls">
          <CardCall :key="call.key" :call="call" @click.native="goToCall(call.key)" />
        </template>
      </div>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ applications, calls }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardCall from '@/components/cards/CardCall'
import CardApplication from '@/components/cards/CardApplication'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LoadingPlaceholder,
    CardCall,
    CardApplication,
  },
  props: {},
  data() {
    return {
      calls: [],
      applications: [],

      // Pagination
      callsPage: 1,
      totalcalls: 0,
      totalcallsPages: 1,
      limitcalls: 50,

      loadingcalls: true,
      loadingApplications: true,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getCalls()
      this.getApplications()
    })
  },

  methods: {
    goToCall(key) {
      this.$router.push({ name: 'call', params: { key: key } })
    },
    goToApplication(callKey, applicationKey, applicationState) {
      this.$router.push({
        name: 'application-view',
        params: {
          key: callKey,
          applicationKey: applicationKey,
          view: ['draft', 'canceled'].includes(applicationState) ? 'metadata' : 'dashboard',
        },
      })
    },

    async getCalls() {
      this.loadingcalls = true
      try {
        const result = await this.api.getCalls({
          state: 'published',
          can_apply: true,
        })
        this.calls = result.calls
      } catch (error) {
        console.error('getcalls Error: ', error)
      } finally {
        this.loadingcalls = false
      }
    },

    async getApplications() {
      this.loadingApplications = true
      try {
        const result = await this.api.getApplications()
        this.applications = result.calls
      } catch (error) {
        console.error('getcalls Error: ', error)
      } finally {
        this.loadingApplications = false
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "calls": "Candidaturas abertas",
    "nocalls": "Sem procedimentos a mostrar.",
    "callType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho",
      "label": "Tipo"
    },
    "callStatus": {
      "published": "Publicado",
      "draft": "Rascunho",
      "closed": "Fechado",
      "archived": "Arquivado"
    },
    "all": "Todos",
    "mycalls": "As minhas candidaturas",
    "orderBy": {
      "createdDate": "Data de criação",
      "title": "Título",
      "type": "Tipo",
      "status": "Estado",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "calls": "Candidaturas abertas",
    "nocalls": "No calls to present.",
    "all": "All",
    "callType": {
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine",
      "label": "Type"
    },
    "callStatus": {
      "published": "Published",
      "draft": "Draft",
      "closed": "Closed",
      "archived": "Archived"
    },
    "orderBy": {
      "createdDate": "Creation date",
      "title": "Title",
      "type": "Type",
      "status": "Status",
      "endDate": "End date",
      "startDate": "Start date"
    },
    "mycalls": "My calls"
  }
}
</i18n>
