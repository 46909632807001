export const APPLICATION_STATES = {
  scholarship: {
    draft: {
      label: { pt: 'Em rascunho', en: 'Em rascunho' },
      color: 'orange',
    },
    submitted: {
      label: { pt: 'Submetida', en: 'Submetida' },
      color: 'primary',
    },
    in_analysis: {
      label: { pt: 'Em análise', en: 'Em análise' },
      color: 'primary',
    },
    in_analysis_waiting: {
      label: { pt: 'Em análise', en: 'Em análise' },
      color: 'orange',
    },
    complaint: {
      label: { pt: 'Audiência de interessados', en: 'Audiência de interessados' },
      color: 'orange',
    },
    complaint_analysis: {
      label: { pt: 'Audiência de interessados (em análise)', en: 'Audiência de interessados (em análise)' },
      color: 'orange',
    },
    waiting_rejection: {
      label: { pt: 'Em análise (sem resposta)', en: 'Em análise (sem resposta)' },
      color: 'orange',
    },
    complaint_waiting_rejection: {
      label: { pt: 'Audiência de interessados (sem resposta)', en: 'Audiência de interessados (sem resposta)' },
      color: 'orange',
    },
    in_progress: {
      label: { pt: 'A decorrer', en: 'A decorrer' },
      color: 'primary',
    },
    closed: {
      label: { pt: 'Terminado', en: 'Terminado' },
      color: 'medium',
    },
    published: {
      label: { pt: 'Publicado', en: 'Publicado' },
      color: 'medium',
    },
    canceled: {
      label: { pt: 'Cancelado', en: 'Cancelado' },
      color: 'medium',
    },
  },
}

export const RESERVATION_STATES = {
  draft: {
    label: { pt: 'Em rascunho', en: 'Em rascunho' },
    color: 'orange',
  },
  submitted: {
    label: { pt: 'Reservado', en: 'Reservado' },
    color: 'light-primary',
  },
  canceled: {
    label: { pt: 'Cancelado', en: 'Cancelado' },
    color: 'medium',
  },
}

export const APPOINTMENT_STATES = {
  draft: {
    label: { pt: 'Em rascunho', en: 'Em rascunho' },
    color: 'orange',
  },
  submitted: {
    label: { pt: 'Submetida', en: 'Submetida' },
    color: 'primary',
  },
  canceled: {
    label: { pt: 'Cancelado', en: 'Cancelado' },
    color: 'medium',
  },
}

export const CALL_STATUS_TAG_COLORS = {
  draft: 'orange',
  published: 'primary',
  deleted: 'medium',
}

export const CALL_TYPES = ['scholarship', 'occupational_medicine']
export const APPLICATION_FILE_TYPES = [
  'household_fiscal_address',
  'family_allowance',
  'permanent_residence',
  'non_existence_of_debts',
  'payment_receipts',
  'iban',
]

export const APPLICATION_RECEIPTS_STATES = {
  pending: {
    label: { pt: 'Em validação', en: 'Waiting validation' },
    tagType: 'orange',
  },
  approved: {
    label: { pt: 'Aceite', en: 'Accepted' },
    tagType: 'primary',
  },
  rejected: {
    label: { pt: 'Rejeitado', en: 'Rejected' },
    tagType: 'medium',
  },
}

export const APPLICATION_REJECTED_REASONS = {
  '0': 'Bolseiro ao abrigo do RABEEES',
  '1': 'Não deslocado',
  '2': 'Não beneficiário de abono de família até ao 3.º escalão',
  '3': 'Incumprimento art.º 5, alínea a) RABEEES - Nacionalidade e autorização de residência permanente',
  '4': 'Incumprimento art.º 5, alínea b) RABEEES - Não matriculado e não inscrito',
  '5': 'Incumprimento art.º 5, alínea c)  RABEEES - Titular de grau',
  '6': 'Incumprimento art.º 5, alínea d) RABEEES - Inscrito a menos de 30 ECTS',
  '7': 'Incumprimento art.º 5, alínea e) RABEEES - Sem aproveitamento escolar',
  '8': 'Incumprimento art.º 5, alínea f) RABEEES - Conclusão do curso fora do período estabelecido',
  '9': 'Incumprimento art.º 5, alínea i) RABEEES - Sem situação contributiva e/ou tributária regularizada',
  '10': 'Instrução incompleta',
  '11': 'Desistência',
  '12': 'Candidatura DGES rejeitada por outro motivo que não rendimento per capita',
  '13': 'Candidatura duplicada',
}

export function arrayNumberRange(start, stop, step) {
  return Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)
}
