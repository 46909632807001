export default [
  {
    path: '/manage/appointments',
    name: 'manage-appointments',
    component: () => import('@/views/manage/ManageAppointments'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/appointment/:key',
    name: 'manage-appointment',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'dashboard' },
  },
  {
    path: '/manage/appointment/:key/dashboard',
    name: 'manage-appointment-dashboard',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'dashboard' },
  },
  {
    path: '/manage/appointment/:key/people',
    name: 'manage-appointment-people',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'people' },
  },
  {
    path: '/manage/appointment/:key/slots',
    name: 'manage-appointment-slots',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'slots' },
  },
  {
    path: '/manage/appointment/:key/settings',
    name: 'manage-appointment-settings',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'settings' },
  },
  {
    path: '/manage/appointment/:key/reservations',
    name: 'manage-appointment-reservations',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'reservations' },
  },
  {
    path: '/manage/appointment/:key/reservation/:reservationKey',
    name: 'manage-appointment-reservation',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'reservation' },
  },
  {
    path: '/manage/appointment/:key/activity',
    name: 'manage-appointment-activity',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'activity' },
  },
  {
    path: '/manage/appointment/:key/notifications',
    name: 'manage-appointment-notifications',
    component: () => import('@/views/manage/ManageAppointment'),
    meta: { requiresAuth: true, forManagers: true, view: 'notifications' },
  },
]
