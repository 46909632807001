<template>
  <fw-layout :back-to-enable="false" :main-sidebar="false" :footer="true">
    <template #header-nav>
      <HeaderSwitchManage />
    </template>
    <template #main-content>
      <PanelUCIdHero
        :user="user"
        class="my-5"
        version="v2"
        :stats-title="isStudent ? 'Candidaturas e apoios sociais' : ''"
        :stats="stats"
        :stats-loading="loadingStats"
        :stats-warning="false"
      />
      <PanelAppointments />
      <PanelCalls v-if="isStudent" />
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import HeaderSwitchManage from '@/components/header/HeaderSwitchManage'
import PanelCalls from '@/components/panels/PanelCalls'
import PanelAppointments from '@/components/panels/occupationalMedicine/PanelAppointments'

export default {
  components: {
    HeaderSwitchManage,
    PanelUCIdHero,
    PanelCalls,
    PanelAppointments,
  },

  data() {
    return {
      loading: false,
      loadingStats: false,
      stats: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    },
  },

  mounted() {
    if (this.isStudent) this.getStats()
  },

  methods: {
    async getStats() {
      this.loadingStats = true

      const statsData = await this.api.getStats()
      if (statsData.stats) {
        this.stats = [
          {
            label: 'Em rascunho',
            value: parseInt(statsData.stats['draft']),
            valueSuffix: null,
          },
          {
            label: 'Submetidas',
            value: parseInt(statsData.stats['submitted']),
            valueSuffix: null,
          },
          {
            label: 'Fechadas',
            value: parseInt(statsData.stats['closed']),
            valueSuffix: null,
          },
        ]
      }
      this.loadingStats = false
    },
  },
}
</script>
