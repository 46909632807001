export default [
  {
    path: '/manage/calls',
    name: 'manage-calls',
    component: () => import('@/views/manage/ManageCalls'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/call/:key',
    name: 'manage-call',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'dashboard' },
  },
  {
    path: '/manage/call/:key/dashboard',
    name: 'manage-call-dashboard',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'dashboard' },
  },
  {
    path: '/manage/call/:key/metadata',
    name: 'manage-call-metadata',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'metadata' },
  },
  {
    path: '/manage/call/:key/people',
    name: 'manage-call-people',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'people' },
  },
  {
    path: '/manage/call/:key/calendar',
    name: 'manage-call-calendar',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'calendar' },
  },
  {
    path: '/manage/call/:key/applicants',
    name: 'manage-call-applicants',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'applicants' },
  },
  {
    path: '/manage/call/:key/applications',
    name: 'manage-call-applications',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'applications' },
  },
  {
    path: '/manage/call/:key/application/:applicationKey',
    name: 'manage-call-application',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'application' },
  },
  {
    path: '/manage/call/:key/activity',
    name: 'manage-call-activity',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'activity' },
  },
  {
    path: '/manage/call/:key/reports',
    name: 'manage-call-reports',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'reports' },
  },
  {
    path: '/manage/call/:key/notifications',
    name: 'manage-call-notifications',
    component: () => import('@/views/manage/ManageCall'),
    meta: { requiresAuth: true, forManagers: true, view: 'notifications' },
  },
]
