<template>
  <div
    class="rounded-xl transition-all shadow-lg hover:shadow-2xl cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"
  >
    <div class="flex flex-col p-4 gap-3 h-full">
      <div class="flex text-sm items-center gap-2 font-semibold text-left opacity-50">
        <div>
          <fw-icon-survey class="h-5 w-5"></fw-icon-survey>
        </div>
        <div class="flex-1">
          Candidatura
        </div>
      </div>
      <div class="flex-1 flex flex-col items-center justify-center">
        <div
          v-if="call.title[language] != null && call.title[language].length > 0"
          class="font-bold text-center w-4/5 mx-auto"
        >
          <v-clamp autoresize :max-lines="3"> {{ call.title[language] }}</v-clamp>
        </div>
        <div v-else class="text-white text-opacity-50 text-center w-3/5 mx-auto">
          Sem título
        </div>
        <div class="opacity-80">
          <fw-label marginless>{{ call.prefix }}{{ call.code }}</fw-label>
        </div>
      </div>
      <div v-if="call.application && applicationStates[call.application.state]" class="flex flex-col gap-3">
        <div>
          <fw-tag size="sm" :type="applicationStates[call.application.state].color" custom-class="px-3">
            {{ applicationStates[call.application.state].label[language] }}
          </fw-tag>
        </div>
        <div class="text-xs text-gray-500">Iniciada em {{ call.application.created_date | formatDateTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { APPLICATION_STATES } from '@/utils/index.js'

export default {
  name: 'CardApplication',
  props: {
    call: {
      type: Object,
    },
  },
  data() {
    return {
      applicationStates: APPLICATION_STATES['scholarship'],
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
  },
}
</script>

<i18n>
  {
    "en": {},
    "pt": {}
  }

</i18n>
