import CoreGetters from '@/fw-modules/fw-core-vue/store/getters'

export default Object.assign(CoreGetters, {
  userPermissions(state) {
    console.log('state', state)
    return {
      isManager: Boolean(state.session.permissions),
      can_access_appointments: Boolean(state.session.permissions?.can_access_appointments),
      can_access_calls: Boolean(state.session.permissions?.can_access_calls),
      can_access_management: Boolean(state.session.permissions?.can_access_management),
    }
  },
  getUserPermissions(state) {
    return state.session.permissions
  },
  isPermissionsLoaded(state) {
    return state.session.permissionsLoaded
  },
})
