import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlSocialSupport
    return api
  },

  async getActivityLogs(params) {
    const response = await this.base().get(`/v1/activity`, { params: params })
    return response.data
  },

  async getCallActivityLogs(params) {
    const response = await this.base().get(`/v1/calls/${params.callKey}/activity`, { params: params })
    return response.data
  },

  async getNotifications(params) {
    const response = await this.base().get(`/v1/notifications`, { params: params })
    return response.data
  },

  async getCallNotifications(params) {
    const response = await this.base().get(`/v1/calls/${params.callKey}/notifications`, { params: params })
    return response.data
  },

  async getNotification(key) {
    const response = await this.base().get(`/v1/notifications/${key}`)
    return response.data
  },

  async searchCallUsers(callKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}/users/search`, { params: params })
    return response.data
  },

  async getCalls(params) {
    const response = await this.base().get(`/v1/calls`, { params: params })
    return response.data
  },

  async createCall(payload) {
    const response = await this.base().post(`/v1/calls`, payload)
    return response.data
  },

  async getCall(callKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}`, { params: params })
    return response.data
  },

  async updateCall(callKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}`, payload)
    return response.data
  },

  async deleteCall(callKey) {
    const response = await this.base().delete(`/v1/calls/${callKey}`)
    return response.data
  },

  async getCallManagers(callKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}/managers`, { params })
    return response.data
  },

  async updateCallManagers(callKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}/managers`, payload)
    return response.data
  },

  async deleteCallManagers(callKey, payload) {
    const response = await this.base().delete(`/v1/calls/${callKey}/managers`, {
      data: payload,
    })
    return response.data
  },

  async getCallApplications(callKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications`, { params })
    return response.data
  },

  async createApplication(callKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}/applications`, payload)
    return response.data
  },

  async getCallApplicationsDistribution(callKey) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/distribute`)
    return response.data
  },

  async doCallApplicationsDistribution(callKey) {
    const response = await this.base().post(`/v1/calls/${callKey}/applications/distribute`)
    return response.data
  },

  // User applications only
  async getApplications() {
    const response = await this.base().get(`/v1/applications`)
    return response.data
  },

  async getApplication(callKey, applicationKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/${applicationKey}`, { params })
    return response.data
  },

  async updateApplication(callKey, applicationKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}/applications/${applicationKey}`, payload)
    return response.data
  },

  async deleteApplicationFile(callKey, applicationKey, fileKeys) {
    const response = await this.base().delete(`/v1/calls/${callKey}/applications/${applicationKey}/files`, {
      data: fileKeys,
    })
    return response.data
  },

  async getApplicationMessages(callKey, applicationKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/${applicationKey}/messages`, { params })
    return response.data
  },

  async addApplicationMessages(callKey, applicationKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}/applications/${applicationKey}/messages`, payload)
    return response.data
  },

  async updateApplicationMessage(callKey, applicationKey, messageKey, payload) {
    const response = await this.base().put(
      `/v1/calls/${callKey}/applications/${applicationKey}/messages/${messageKey}`,
      payload
    )
    return response.data
  },

  async deleteApplicationMessage(callKey, applicationKey, messageKey, payload) {
    const response = await this.base().delete(
      `/v1/calls/${callKey}/applications/${applicationKey}/messages/${messageKey}`,
      payload
    )
    return response.data
  },

  //Get managers available to be assigned to this application
  async getApplicationManagers(callKey, applicationKey) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/${applicationKey}/managers`)
    return response.data
  },

  //save application managers
  async createApplicationManagers(callKey, applicationKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}/applications/${applicationKey}/managers`, payload)
    return response.data
  },

  async getApplicationActivity(callKey, applicationKey, params) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/${applicationKey}/activity`, { params })
    return response.data
  },

  async deleteApplicationManagers(callKey, applicationKey, payload) {
    const response = await this.base().delete(`/v1/calls/${callKey}/applications/${applicationKey}/managers`, {
      data: payload,
    })
    return response.data
  },

  // User (everyone)
  async getUserInfo() {
    const response = await this.base().get(`/v1/user`)
    return response.data
  },

  async getStats() {
    const response = await this.base().get(`/v1/user/stats`)
    return response.data
  },

  // Manager dashboard
  async getUserWeekEvents(weekNumber, year, payload = {}) {
    const response = await this.base().get(`/v1/user/events/${weekNumber}/${year}`, { params: payload })
    return response.data
  },

  // Application Receipts
  // --------------------

  // Get receipts (managers and candidates)
  async getApplicationReceipts(callKey, applicationKey) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/${applicationKey}/receipts`, {
      params: { limit: 200 },
    })
    return response.data
  },

  // Get receipt data (managers and candidates)
  async getApplicationReceipt(callKey, applicationKey, receiptKey) {
    const response = await this.base().get(`/v1/calls/${callKey}/applications/${applicationKey}/receipt/${receiptKey}`)
    return response.data
  },

  // Upload receipts (candidates only) - bulk upload
  async uploadReceipts(callKey, applicationKey, payload) {
    const response = await this.base().post(`/v1/calls/${callKey}/applications/${applicationKey}/receipts`, payload)
    return response.data
  },

  // Validate receipt (manager only)
  async validateReceipt(callKey, applicationKey, receiptKey, payload) {
    const response = await this.base().post(
      `/v1/calls/${callKey}/applications/${applicationKey}/receipts/${receiptKey}`,
      payload
    )
    return response.data
  },

  async updateAppointmentPublicSlotPresence(token, payload) {
    const response = await this.base().post(`/v1/public/slots/t/${token}`, payload)
    return response.data
  },

  // Get reports
  async getCallReports(callKey) {
    const response = await this.base().get(`/v1/calls/${callKey}/reports`)
    return response.data
  },

  // Copy files from a message to receipts
  async copyFilesFromMessageToReceipts(callKey, applicationKey, messageKey, payload) {
    const response = await this.base().post(
      `/v1/calls/${callKey}/applications/${applicationKey}/messages/${messageKey}`,
      payload
    )
    return response.data
  },

  /*****************
   *               *
   * Appointments  *
   *               *
   ****************/

  // Manager dashboard
  async getAppointmentsUserWeekEvents(weekNumber, year, payload = {}) {
    const response = await this.base().get(`/v1/appointments/user/events/${weekNumber}/${year}`, { params: payload })
    return response.data
  },

  async getAppointmentActivityLogs(params) {
    const response = await this.base().get(`/v1/appointments/${params.appointmentKey}/activity`, { params: params })
    return response.data
  },

  async getAppointmentNotifications(params) {
    const response = await this.base().get(`/v1/appointments/${params.appointmentKey}/notifications`, {
      params: params,
    })
    return response.data
  },

  async getAppointmentNotification(key, params) {
    const response = await this.base().get(`/v1/appointments/${params.appointmentKey}/notifications/${key}`, {
      params: params,
    })
    return response.data
  },

  async searchAppointmentUsers(appointmentKey, params) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/users/search`, { params: params })
    return response.data
  },

  async bulkSearchAppointmentUsers(appointmentKey, params) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/users/bulk-search`, { params: params })
    return response.data
  },

  async getAppointments(params) {
    const response = await this.base().get(`/v1/appointments`, { params: params })
    return response.data
  },

  async createAppointment(payload) {
    const response = await this.base().post(`/v1/appointments`, payload)
    return response.data
  },

  async getAppointment(appointmentKey, params) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}`, { params: params })
    return response.data
  },

  async updateAppointment(appointmentKey, payload) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}`, payload)
    return response.data
  },

  async deleteAppointment(appointmentKey) {
    const response = await this.base().delete(`/v1/appointments/${appointmentKey}`)
    return response.data
  },

  async getAppointmentManagers(appointmentKey, params) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/managers`, { params })
    return response.data
  },

  async updateAppointmentManagers(appointmentKey, payload) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}/managers`, payload)
    return response.data
  },

  async deleteAppointmentManagers(appointmentKey, payload) {
    const response = await this.base().delete(`/v1/appointments/${appointmentKey}/managers`, {
      data: payload,
    })
    return response.data
  },
  async getAppointmentsByToken(token) {
    const response = await this.base().get(`/v1/public/slots/t/${token}`)
    return response.data
  },
  async getAppointmentReservations(appointmentKey, params) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/reservations`, { params })
    return response.data
  },

  async createReservation(appointmentKey, payload) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}/reservations`, payload)
    return response.data
  },

  async refreshViewToken(appointmentKey) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}/public/slots`)
    return response.data
  },

  // User reservations only
  async getReservations() {
    const response = await this.base().get(`/v1/reservations`)
    return response.data
  },

  async getReservation(appointmentKey, reservationKey, params) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/reservations/${reservationKey}`, {
      params,
    })
    return response.data
  },

  async updateReservation(appointmentKey, reservationKey, payload) {
    const response = await this.base().post(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}`,
      payload
    )
    return response.data
  },

  async getReservationMessages(appointmentKey, reservationKey, params) {
    const response = await this.base().get(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/messages`,
      { params }
    )
    return response.data
  },

  async addReservationMessages(appointmentKey, reservationKey, payload) {
    const response = await this.base().post(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/messages`,
      payload
    )
    return response.data
  },

  async updateReservationMessage(appointmentKey, reservationKey, messageKey, payload) {
    const response = await this.base().put(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/messages/${messageKey}`,
      payload
    )
    return response.data
  },

  async deleteReservationMessage(appointmentKey, reservationKey, messageKey, payload) {
    const response = await this.base().delete(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/messages/${messageKey}`,
      payload
    )
    return response.data
  },

  //Get managers available to be assigned to this reservation
  async getReservationManagers(appointmentKey, reservationKey) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/reservations/${reservationKey}/managers`)
    return response.data
  },

  //save reservation managers
  async createReservationManagers(appointmentKey, reservationKey, payload) {
    const response = await this.base().post(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/managers`,
      payload
    )
    return response.data
  },

  async getReservationActivity(appointmentKey, reservationKey, params) {
    const response = await this.base().get(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/activity`,
      { params }
    )
    return response.data
  },

  async deleteReservationManagers(appointmentKey, reservationKey, payload) {
    const response = await this.base().delete(
      `/v1/appointments/${appointmentKey}/reservations/${reservationKey}/managers`,
      {
        data: payload,
      }
    )
    return response.data
  },

  async getAppointmentSlots(appointmentKey) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/slots`)
    return response.data
  },

  async createAppointmentSlots(appointmentKey, payload) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}/slots`, payload)
    return response.data
  },

  async updateAppointmentSlots(appointmentKey, payload) {
    const response = await this.base().put(`/v1/appointments/${appointmentKey}/slots`, payload)
    return response.data
  },

  async deleteAppointmentSlots(appointmentKey, payload) {
    const response = await this.base().delete(`/v1/appointments/${appointmentKey}/slots`, { data: payload })
    return response.data
  },

  async getAppointmentApplicants(appointmentKey) {
    const response = await this.base().get(`/v1/appointments/${appointmentKey}/applicants`)
    return response.data
  },

  async updateAppointmentApplicants(appointmentKey, userKeys) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}/applicants`, { user_keys: userKeys })
    return response.data
  },

  /**
   *
   * @param {*} appointmentKey
   * @param {*} userKeys If left empty all without invitation will be sent the email
   */
  async resendAppointmentApplicantsInvite(appointmentKey, userKeys) {
    const response = await this.base().post(`/v1/appointments/${appointmentKey}/applicants/resend`, {
      user_keys: userKeys,
    })
    return response.data
  },
}
