<template>
  <div v-if="appointments.length">
    <fw-panel
      :title="$t('appointments')"
      subtitle="Agendamentos"
      :counter="appointments?.length ?? 0"
      :counter-total="pagination.total_items"
      class="mt-10 mb-5"
    >
      <fw-panel-info
        v-if="!loading && (!appointments || !appointments.length)"
        type="basic"
        simple
        clean
        class="text-center text-gray-500"
      >
        Não existem procedimentos com agendamentos abertas neste momento.
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
        <template v-for="appointment in appointments">
          <CardAppointment
            :key="appointment.key"
            :appointment="appointment"
            @click.native="goToAppointment(appointment.key)"
          />
        </template>
      </div>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ appointments }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardAppointment from '@/components/cards/CardAppointment'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LoadingPlaceholder,
    CardAppointment,
  },
  props: {},
  data() {
    return {
      appointments: [],
      loading: true,
      pagination: {
        current_page: 1,
        total_pages: 1,
        active_limit: 30,
        total_items: 0,
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  mounted() {
    this.getAppointments()
  },

  methods: {
    goToAppointment(key) {
      this.$router.push({ name: 'appointment', params: { key: key } })
    },

    goToReservation(appointmentKey, reservationKey, reservationState) {
      this.$router.push({
        name: 'reservation-view',
        params: {
          key: appointmentKey,
          reservationKey: reservationKey,
          view: ['draft', 'canceled'].includes(reservationState) ? 'metadata' : 'dashboard',
        },
      })
    },

    async getAppointments() {
      this.loading = true
      try {
        const result = await this.api.getAppointments({
          state: 'published',
          can_apply: true,
          for_public: true,
        })
        this.appointments = result.appointments
        this.pagination = result.pagination
      } catch (error) {
        console.error('getappointments Error: ', error)
      } finally {
        await utils.sleep(100)
        this.loading = false
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "appointments": "Medicina do Trabalho",
    "noappointments": "Sem agendamentos a mostrar.",
    "appointmentStatus": {
      "published": "Publicado",
      "draft": "Rascunho",
      "closed": "Fechado",
      "archived": "Arquivado"
    }
  },
  "en": {
    "appointments": "Medicina do Trabalho",
    "noappointments": "No appointments to present.",
    "appointmentStatus": {
      "published": "Published",
      "draft": "Draft",
      "closed": "Closed",
      "archived": "Archived"
    }
  }
}
</i18n>
