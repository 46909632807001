import Home from '@/views/Home'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/call/:key',
    name: 'call',
    component: () => import('@/views/Call'),
    meta: { requiresAuth: true },
  },
  {
    path: '/call/:key/application/:applicationKey',
    name: 'application',
    component: () => import('@/views/Application'),
    meta: { requiresAuth: true },
  },
  {
    path: '/call/:key/application/:applicationKey/:view',
    name: 'application-view',
    component: () => import('@/views/Application'),
    meta: { requiresAuth: true },
  },
  {
    path: '/view/t/:token',
    name: 'appointment-public-token',
    component: () => import('@/views/ViewAppointmentPublic'),
    meta: { requiresAuth: false },
  },
  {
    path: '/appointment/:key/view',
    name: 'appointment-public',
    component: () => import('@/views/ViewAppointmentPublic'),
    meta: { requiresAuth: true },
  },
  {
    path: '/appointment/:key',
    name: 'appointment',
    component: () => import('@/views/Appointment'),
    meta: { requiresAuth: true },
  },
]
