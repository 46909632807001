<template>
  <BlockUserDetails :user="user" :loading="loading"> </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'UserDetails',
  components: { BlockUserDetails },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    spaceQueues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      queues: {},
      selectedQueues: [],
    }
  },

  validations: {
    selectedQueues: { required, min: minLength(1) },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },
  },
  created() {
    this.getQueues()
  },
  methods: {
    saveQueues() {
      this.$emit('update-queues', {
        users: [this.user.key],
        queues: this.selectedQueues,
        refresh: false,
      })
    },

    async getQueues() {
      this.loading = true
      this.selectedQueues = this.user.queueKeys
      this.spaceQueues.forEach(queue => {
        if (queue.can_view != false) {
          this.queues[queue.key] = queue
        }
      })
      this.loading = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "queues": "Filas",
    "noQueues": "Sem filas atribuídas.",
    "role": {
      "space-admin": "Gestor de espaço",
      "queue-admin": "Gestor da(s) fila(s)",
      "queue-worker": "Trabalhador da(s) fila(s)"
    },
    "chooseAtLeastOneQueue": "Escolha pelo menos uma fila"
  },
  "en": {
    "queues": "Queues",
    "noQueues": "No queues assigned.",
    "role": {
      "space-admin": "Space manager",
      "queue-admin": "Queue manager",
      "queue-worker": "Queue worker"
    },
    "chooseAtLeastOneQueue": "Choose at least one queue"
  }
}
</i18n>
