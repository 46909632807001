var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-xl transition-all shadow-lg hover:shadow-2xl cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"},[_c('div',{staticClass:"flex flex-col p-4 gap-2.5 h-full"},[_c('div',{staticClass:"flex text-sm gap-2 font-semibold text-left text-gray-500"},[_c('div',{staticClass:"flex-shrink-0"},[(!_vm.appointment?.reservation?.state === 'submitted')?_c('fw-icon-calendar',{staticClass:"h-5 w-5"}):_c('fw-icon-calendar-event',{staticClass:"h-5 w-5"})],1),_c('div',{staticClass:"flex-1 flex items-center justify-between"},[_vm._v(_vm._s(_vm.appointment.prefix)+_vm._s(_vm.appointment.code))]),_c('div',[(_vm.appointment.state == 'published')?_c('fw-dot',{attrs:{"color":"primary","inline":""}}):_vm._e()],1)]),_c('div',{staticClass:"flex-1 rounded-lg p-4 text-sm",class:{
        'bg-gray-100': !_vm.appointment?.reservation || _vm.appointment?.reservation?.state !== 'submitted',
        'bg-primary bg-opacity-10': _vm.appointment?.reservation && _vm.appointment?.reservation?.state === 'submitted',
      }},[(_vm.appointment.state == 'published' && !_vm.appointment.reservation)?_c('div',{staticClass:"text-gray-500 flex flex-col gap-2"},[_c('div',[_vm._v(" Ainda não agendou a sua consulta! ")]),_c('div',[_c('fw-button',{attrs:{"type":"primary","size":"sm"}},[_vm._v("Agendar")])],1)]):(_vm.appointment.reservation)?_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',[_c('fw-tag',{attrs:{"size":"sm","type":_vm.reservationStates[_vm.appointment.reservation.state].color,"custom-class":"px-3"}},[_vm._v(" "+_vm._s(_vm.reservationStates[_vm.appointment.reservation.state].label[_vm.language])+" ")])],1),_c('div',{staticClass:"flex flex-col gap-0.5"},[_c('div',{staticClass:"text-xs opacity-50"},[_vm._v("Data da consulta")]),_c('div',{staticClass:"text-base font-bold text-center",class:{
              'text-primary': _vm.appointment.reservation.state === 'submitted',
              'text-gray-500': _vm.appointment.reservation.state !== 'submitted',
            }},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.appointment.reservation.time_slot.start_datetime))+" ")])])]):_vm._e()]),(_vm.appointment.reservations_end_date && _vm.appointment.state == 'published')?_c('div',{staticClass:"text-xs font-semibold text-primary"},[_vm._v(" Agendamento disponível até dia "+_vm._s(_vm._f("formatDate")(_vm.appointment.reservations_end_date))+" ")]):(_vm.appointment.reservations_end_date && _vm.appointment.state == 'closed')?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(" Agendamentos encerrados em "+_vm._s(_vm._f("formatDate")(_vm.appointment.reservations_end_date))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }