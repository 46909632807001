<template>
  <div
    class="rounded-xl transition-all shadow-lg hover:shadow-2xl cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0"
  >
    <div class="flex flex-col p-4 gap-2.5 h-full">
      <div class="flex text-sm gap-2 font-semibold text-left text-gray-500">
        <div class="flex-shrink-0">
          <fw-icon-calendar v-if="!appointment?.reservation?.state === 'submitted'" class="h-5 w-5" />
          <fw-icon-calendar-event v-else class="h-5 w-5" />
        </div>
        <div class="flex-1 flex items-center justify-between">{{ appointment.prefix }}{{ appointment.code }}</div>
        <div>
          <fw-dot v-if="appointment.state == 'published'" color="primary" inline />
        </div>
      </div>

      <div
        class="flex-1 rounded-lg p-4 text-sm"
        :class="{
          'bg-gray-100': !appointment?.reservation || appointment?.reservation?.state !== 'submitted',
          'bg-primary bg-opacity-10': appointment?.reservation && appointment?.reservation?.state === 'submitted',
        }"
      >
        <div
          v-if="appointment.state == 'published' && !appointment.reservation"
          class="text-gray-500 flex flex-col gap-2"
        >
          <div>
            Ainda não agendou a sua consulta!
          </div>
          <div>
            <fw-button type="primary" size="sm">Agendar</fw-button>
          </div>
        </div>
        <div v-else-if="appointment.reservation" class="flex flex-col gap-2">
          <div>
            <fw-tag size="sm" :type="reservationStates[appointment.reservation.state].color" custom-class="px-3">
              {{ reservationStates[appointment.reservation.state].label[language] }}
            </fw-tag>
          </div>
          <div class="flex flex-col gap-0.5">
            <div class="text-xs opacity-50">Data da consulta</div>
            <div
              class="text-base font-bold text-center"
              :class="{
                'text-primary': appointment.reservation.state === 'submitted',
                'text-gray-500': appointment.reservation.state !== 'submitted',
              }"
            >
              {{ appointment.reservation.time_slot.start_datetime | formatDateTime }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="appointment.reservations_end_date && appointment.state == 'published'"
        class="text-xs font-semibold text-primary"
      >
        Agendamento disponível até dia {{ appointment.reservations_end_date | formatDate }}
      </div>
      <div v-else-if="appointment.reservations_end_date && appointment.state == 'closed'" class="text-xs text-gray-500">
        Agendamentos encerrados em {{ appointment.reservations_end_date | formatDate }}
      </div>
    </div>
  </div>
</template>

<script>
import { RESERVATION_STATES } from '@/utils/index.js'

export default {
  props: {
    appointment: {
      type: Object,
    },
  },
  data() {
    return {
      reservationStates: RESERVATION_STATES,
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
  },
}
</script>

<i18n>
  {
    "en": {},
    "pt": {}
  }
</i18n>
