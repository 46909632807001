import manageAppointments from './manageAppointments'
import manageCall from './manageCall'

export default [
  {
    path: '/manage',
    name: 'manage',
    component: () => import('@/views/manage/ManageHome'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/activity',
    name: 'activity',
    component: () => import('@/views/manage/ManageActivity'),
    meta: { requiresAuth: true, forManagers: true },
  },
  {
    path: '/manage/notifications',
    name: 'manage-notifications',
    component: () => import('@/views/manage/ManageNotifications'),
    meta: { requiresAuth: true, forManagers: true },
  },
  ...manageCall,
  ...manageAppointments,
]
