<template>
  <div
    class="rounded-xl transition-all shadow-lg hover:shadow-2xl cursor-pointer overflow-hidden relative bg-center bg-cover text-center flex-shrink-0 min-h-52"
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
  >
    <div class="exam-cover-gradient flex flex-col p-4 gap-5 text-white h-full">
      <div class="flex text-sm items-center gap-2 font-semibold text-left">
        <div class="flex-1 opacity-80">
          Apoio
        </div>
        <div class="uppercase border border-white opacity-80 rounded px-1 py-0.5 text-xs font-semibold">
          {{ call.prefix }}{{ call.code }}
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-center gap-2 mb-2">
        <div
          v-if="call.title[language] != null && call.title[language].length > 0"
          class="font-bold text-center w-4/5 mx-auto text-xl"
        >
          <v-clamp autoresize :max-lines="3"> {{ call.title[language] }}</v-clamp>
        </div>
        <div v-else class="text-white text-opacity-50 text-center w-3/5 mx-auto">
          Sem título
        </div>
        <div v-if="isOpenToApplications">
          <div class="inline-flex flex-shrink-0 text-primary relative gap-2">
            <div class="font-semibold">Candidaturas abertas</div>
            <fw-dot color="primary" inline class="mt-1" />
          </div>
          <div class="text-primary text-xs font-medium opacity-80">Até {{ call.end_date | formatDateTime }}</div>
        </div>
        <div v-else-if="isClosedToApplications">
          <div class="text-sm opacity-70 font-medium">
            Candidaturas encerradas
          </div>
        </div>
        <div v-else-if="isOpeningSoon">
          <div class="text-primary font-semibold">Brevemente</div>
          <div class="text-primary text-xs opacity-80 font-medium">
            A partir de {{ call.start_date | formatDateTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCall',
  props: {
    call: {
      type: Object,
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    backgroundImage() {
      return 'https://static.fw.uc.pt/apps/exams/covers/exam-cover-abstract-02.jpg'
    },

    // Checks
    isRunning() {
      return !this.call.state.includes(['draft', 'closed'])
    },
    isOpenToApplications() {
      return this.call.time_to_end > 0 && this.call.time_to_start < 0 && this.call.state == 'published'
    },
    isClosedToApplications() {
      return this.call.time_to_end < 1
    },
    isOpeningSoon() {
      return this.call.time_to_start > 0 && this.call.state == 'published'
    },
    isStudent() {
      return this.call.validations.is_student
    },
  },
}
</script>

<i18n>
  {
    "en": {},
    "pt": {}
  }
</i18n>

<style scoped>
.exam-cover-gradient {
  background: rgb(38, 38, 38);
  background: linear-gradient(0deg, rgba(38, 38, 38, 1) 25%, rgba(54, 54, 54, 0.48) 80%);
}
.progress-gradient {
  background: rgb(56, 195, 177);
  background: linear-gradient(90deg, rgba(56, 195, 177, 1) 0%, rgba(116, 246, 230, 1) 85%);
}
</style>
